<template>
  <div class="mt-5 mb-5">
    <ScheduleDashboard class="mt-2"></ScheduleDashboard>
  </div>
</template>

<script>
const ScheduleDashboard = () =>
  import("../../../components/dashboard/psyhDashboard/Schedule");

export default {
  name: "Schedule",
  components: {
    ScheduleDashboard,
  },
};
</script>

<style scoped></style>
